import React, {useState} from "react";
import { CSSTransition } from "react-transition-group";
import Juniores from "./img/2024_juniores.png";
import './MenusFormacao.css';

const MenuJuniores = () => {
  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  // Helper function to calculate the height of the menu
  const calcHeight = (el) => {
    const height = el.scrollHeight;
    setMenuHeight(height);
  };

  // Event handler for clicking a menu item
  const handleMenuClick = (menu) => {
    setOpen(!open);
    setActiveMenu(menu);
  };

  // Event handler for clicking the logo
  const handleLogoClick = () => {
    if (open) {
      setOpen(false);
      setActiveMenu("main");
      setMenuHeight(null);
      setMenuVisible(false);
    } else {
      setOpen(true);
      setMenuVisible(true);
    }
  };

  return (
    <div className="responsive-menu">
      <img
        src={Juniores}
        alt="Logo"
        onClick={handleLogoClick}
        className="w-64 h-30 object-full"
      />

      {menuVisible && (
        <div className="dropdown">
          <CSSTransition
            in={open && activeMenu === "main"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem
              label="Masculino"
              goToMenu = "masculino"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="Feminino"
              goToMenu = "feminino"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

        {/* Menu - Juniores - Masculino */}

          <CSSTransition
            in={activeMenu === "masculino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJUNM_1Fase" 
              label="1º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJUNM_2Fase" 
              label="2º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJUNM_Final" 
              label="Fase Final"
              setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "NJUNM_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="masculino" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              {/*<DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DO ALENTEJO E ALGARVE"
                subtipo="AVAL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                 campeonato="NJUNM"
                 fase="1"
                 serie="A"
                 label="ASSOCIAÇÃO DE VOLEIBOL DE BRAGA"
                 subtipo="AVB"
                 onSubmit="true"
                 setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                  campeonato="NJUNM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE COIMBRA"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  campeonato="NJUNM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE DESPORTOS DA ILHA DO FAIAL"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NJUNM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DA GUARDA"
                  subtipo="AVG"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE LEIRIA"
                subtipo="ADL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
              <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE LISBOA"
                subtipo="AVL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NJUNM_AV_Pico" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DO PICO"
                  setActiveMenu={setActiveMenu}
                    />*/}
              <DropdownItem 
                  goToMenu="NJUNM_AVP" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DO PORTO"
                  setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NJUNM_AV_SantaMaria" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA SANTA MARIA"
                  setActiveMenu={setActiveMenu}
                  />
                  <DropdownItem 
                  goToMenu="NJUNM_AV_Terceira" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA TERCEIRA"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NJUNM"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE VIANA DE CASTELO"
                  subtipo="AVVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE VISEU"
                subtipo="AVV"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NJUNM_AV_Flores" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DAS FLORES"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL TRÁS-OS-MONTES"
                subtipo="AVTM"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
            </div>
          </CSSTransition>

          {/* Menu Juniores Masculino - 1ºFase - AVP*/}

          <CSSTransition
                in={activeMenu === "NJUNM_AVP"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
          <div className="menu">
            <DropdownItem
              label="1ª Fase"
              goToMenu = "NJUNM_AVP_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NJUNM_AVP_2Fase"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

        {/* Menu Juniores Masculino - 1ºFase - AVP - 1ªFase*/}

          <CSSTransition
            in={activeMenu === "NJUNM_AVP_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJUNF_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="A"
                subtipo="AVP"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="B"
                subtipo="AVP"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Juniores Masculino - 1ºFase - AVP - 1ªFase - 2ªFase*/}

          <CSSTransition
            in={activeMenu === "NJUNM_AVP_2Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJUNF_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="SA"
                subtipo="AVP"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="SB"
                subtipo="AVP"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNM"
                fase="1"
                serie="SC"
                subtipo="AVP"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 


          <CSSTransition
            in={activeMenu === "NJUNM_2Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
    <DropdownItem 
  campeonato="NJUNM"
  fase="2"
  serie="A"
  label="Série A"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NJUNM"
  fase="2"
  serie="B"
  label="Série B"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NJUNM"
  fase="2"
  serie="C"
  label="Série C"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NJUNM"
  fase="2"
  serie="D"
  label="Série D"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "NJUNM_Final"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NJUNM"
                fase="F"
                serie="CN"
                label="Apuramento do Campeão Nacional"
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          {/* Menu - Juniores - Feminino */}

          <CSSTransition
            in={activeMenu === "feminino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJUNF_1Fase" 
              label="1º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJUNF_2Fase" 
              label="2º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJUNF_Final" 
              label="Fase Final"
              setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition>

          {/* Menu Juniores Feminino - 1ªFase*/}

          <CSSTransition
            in={activeMenu === "NJUNF_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="feminino" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              {/*<DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DO ALENTEJO E ALGARVE"
                subtipo="AVAL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
                />*/}
              <DropdownItem 
                 goToMenu="NJUNF_AVB"
                 label="ASSOCIAÇÃO DE VOLEIBOL DE BRAGA"
                 setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                  campeonato="NJUNF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE COIMBRA"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  campeonato="NJUNF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE DESPORTOS DA ILHA DO FAIAL"
                  subtipo="AVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NJUNF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DA GUARDA"
                  subtipo="AVG"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE LEIRIA"
                subtipo="ADL"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
              <DropdownItem 
                goToMenu="NJUNF_AVL"
                label="ASSOCIAÇÃO DE VOLEIBOL DE LISBOA"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NJUNF_AV_Pico" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DO PICO"
                  setActiveMenu={setActiveMenu}
                    />*/}
              <DropdownItem 
                  goToMenu="NJUNF_AVP" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DO PORTO"
                  setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NJUNF_AV_SantaMaria" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA SANTA MARIA"
                  setActiveMenu={setActiveMenu}
                  />*/}
                  {/*<DropdownItem 
                  goToMenu="NJUNF_AV_Terceira" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA TERCEIRA"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                  campeonato="NJUNF"
                  fase="1"
                  serie="A"
                  label="ASSOCIAÇÃO DE VOLEIBOL DE VIANA DE CASTELO"
                  subtipo="AVVC"
                  onSubmit="true"
                  setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL DE VISEU"
                subtipo="AVV"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
                  {/*<DropdownItem 
                  goToMenu="NJUNF_AV_Flores" 
                  label="ASSOCIAÇÃO DE VOLEIBOL DA ILHA DAS FLORES"
                  setActiveMenu={setActiveMenu}
                  />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="A"
                label="ASSOCIAÇÃO DE VOLEIBOL TRÁS-OS-MONTES"
                subtipo="AVTM"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />*/}
            </div>
          </CSSTransition>

      {/* Menu Juniores Feminino - 1ºFase - AVP*/}

          <CSSTransition
                in={activeMenu === "NJUNF_AVP"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
          <div className="menu">
          <DropdownItem 
              goToMenu="NJUNF_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
              label="1ª Fase"
              goToMenu = "NJUNF_AVP_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NJUNF_AVP_2Fase"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          {/* Menu Juniores Feminino - 1ªFase - AVP - 1º Fase*/}

          <CSSTransition
                in={activeMenu === "NJUNF_AVP_1Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJUNF_AVP" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="A"
                subtipo="AVP"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="B"
                subtipo="AVP"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="C"
                subtipo="AVP"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Juniores Feminino - 1ªFase - AVP - 2º Fase*/}

          <CSSTransition
                in={activeMenu === "NJUNF_AVP_2Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJUNF_AVP" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="S1"
                subtipo="AVP"
                label="Série 1"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="S2"
                subtipo="AVP"
                label="Série 2"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="S3"
                subtipo="AVP"
                label="Série 3"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Juniores Feminino - AVL*/}

        <CSSTransition
        in={activeMenu === "NJUNF_AVL"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
         >
          <div className="menu">
          <DropdownItem 
              goToMenu="NJUNF_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
              label="1ª Fase"
              goToMenu = "NJUNF_AVL_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NJUNF_AVL_2Fase"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          {/* Menu Juniores Feminino - Fase - AVL*/}

          <CSSTransition
                in={activeMenu === "NJUNF_AVL_1Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJUNF_AVL" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="A"
                subtipo="AVL"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="B"
                subtipo="AVL"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="C"
                subtipo="AVL"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="D"
                subtipo="AVL"
                label="Série D"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Cadetes Feminino - 1ªFase - AVL - 2º Fase*/}

          <CSSTransition
                in={activeMenu === "NJUNF_AVL_2Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJUNF_AVL" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="SA"
                subtipo="AVL"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="SB"
                subtipo="AVL"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="SC"
                subtipo="AVL"
                label="Série C"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="SD"
                subtipo="AVL"
                label="Série D"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="SE"
                subtipo="AVL"
                label="Série E"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

     {/* Menu Juniores Feminino - 1ºFase - AVB*/}

        <CSSTransition
        in={activeMenu === "NJUNF_AVB"}
        timeout={500}
        classNames="menu-secondary"
        unmountOnExit
        onEnter={calcHeight}
         >
          <div className="menu">
            <DropdownItem
              label="1ª Fase"
              goToMenu = "NJUNF_AVB_1Fase"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="2ª Fase"
              goToMenu = "NJUNF_AVB_2Fase"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          {/* Menu Juniores Feminino - 1ºFase - AVB  - 1ºFase*/}

          <CSSTransition
                in={activeMenu === "NJUNF_AVB_1Fase"}
                timeout={500}
                classNames="menu-secondary"
                unmountOnExit
                onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NINFF_1Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="A"
                subtipo="AVB"
                label="Série A"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="B"
                subtipo="AVB"
                label="Série B"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

       {/* Menu Juniores Feminino - 1ªFase - AVB - 2º Fase*/}

          <CSSTransition
            in={activeMenu === "NJUNF_AVB_2Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJUNF_AVB" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="S1"
                subtipo="AVB"
                label="Série 1"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJUNF"
                fase="1"
                serie="S2"
                subtipo="AVB"
                label="Série 2"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition> 

          {/* Menu Juniores Feminino - 2ªFase*/}

          <CSSTransition
  in={activeMenu === "NJUNF_2Fase"}
  timeout={500}
  classNames="menu-secondary"
  unmountOnExit
  onEnter={calcHeight}
>
  <div>
    <DropdownItem 
      goToMenu="main" 
      label="&larr; Voltar"
      setActiveMenu={setActiveMenu}
    />
    <div className="menu">
    <DropdownItem 
  campeonato="NJUNF"
  fase="2"
  serie="A"
  label="Série A"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NJUNF"
  fase="2"
  serie="B"
  label="Série B"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NJUNF"
  fase="2"
  serie="C"
  label="Série C"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
<DropdownItem 
  campeonato="NJUNF"
  fase="2"
  serie="D"
  label="Série D"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
  formAction={true}
/>
    </div>
  </div>
</CSSTransition>

          {/* Menu Juniores Feminino - Fase Final*/}

          <CSSTransition
            in={activeMenu === "NJUNF_Final"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NJUNF"
                fase="F"
                serie="F"
                label="Apuramento do Campeão Nacional"
                onSubmit="true"
              />
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  );
};


const DropdownItem = ({ campeonato, fase, serie, subtipo, label, goToMenu, setActiveMenu, onSubmit }) => {
  const buttonRef = React.useRef(null);
  
  const handleClick = () => {
    if (goToMenu) {
      setActiveMenu(goToMenu);
    } else if (onSubmit) {
      const selector = `#${campeonato}-${fase}-${serie}-${subtipo}`.replace(/[^a-zA-Z0-9-_]/g, '-');
      const form = document.querySelector(selector);
      if (form) {
        form.submit();
      }
    }
  };
  
  let actionUrl = "https://www.portugalvoleibol.com/classificacao/classificacoes_assoc";
  
   if (fase === "2" || fase === "F") {
    actionUrl = "https://www.portugalvoleibol.com/classificacao/classificacoes_react";
  }
  
  return (
    <div className="menu-item" onClick={handleClick}>
      {onSubmit ? (
        <form
          onSubmit={(e) => {
          }}
          action={actionUrl}
          method="POST"
        >
          <input type="hidden" name="campeonato" value={campeonato} />
          <input type="hidden" name="fase" value={fase} />
          <input type="hidden" name="serie" value={serie} />
          <input type="hidden" name="subtipo" value={subtipo} />
          <button type="submit" ref={buttonRef}>
            {label}
          </button>
        </form>
      ) : (
        <button type="button" ref={buttonRef}>
          {label}
        </button>
      )}
    </div>
  );
};
    export default MenuJuniores;