import React, {useState} from "react";
import { CSSTransition } from "react-transition-group";
import Sub21JB from "./img/2024_sub21_jb.png";
import './MenusFormacao.css';

const MenuSub21JB = () => {
  const [open, setOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("main");
  const [menuHeight, setMenuHeight] = useState(null);
  const [menuVisible, setMenuVisible] = useState(false);

  // Helper function to calculate the height of the menu
  const calcHeight = (el) => {
    const height = el.scrollHeight;
    setMenuHeight(height);
  };

  // Event handler for clicking a menu item
  const handleMenuClick = (menu) => {
    setOpen(!open);
    setActiveMenu(menu);
  };

  // Event handler for clicking the logo
  const handleLogoClick = () => {
    if (open) {
      setOpen(false);
      setActiveMenu("main");
      setMenuHeight(null);
      setMenuVisible(false);
    } else {
      setOpen(true);
      setMenuVisible(true);
    }
  };

  return (
    <div className="responsive-menu">
      <img
        src={Sub21JB}
        alt="Logo"
        onClick={handleLogoClick}
        className="w-64 h-30 object-full"
      />

      {menuVisible && (
        <div className="dropdown">
           <CSSTransition
            in={open && activeMenu === "main"}
            timeout={500}
            classNames="menu-primary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu-primary">
            <DropdownItem
              label="Masculino"
              goToMenu = "masculino"
              setActiveMenu={setActiveMenu}
            />
               <DropdownItem
              label="Feminino"
              goToMenu = "feminino"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "masculino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJNBM_1Fase" 
              label="1º Fase"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
              goToMenu="NJNBM_2Fase" 
              label="2º Fase"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem 
              goToMenu="NJNBM_Final" 
              label="Fase Final"
              setActiveMenu={setActiveMenu}
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "NJNBM_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="masculino" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem
                campeonato="NJNBM"
                fase="1"
                serie="N"
                label="Campeonato Nacional Sub-21 (JB) - Norte"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NJNBM"
                fase="1"
                serie="SA"
                label="Campeonato Nacional Sub-21 (JB) - Sul A"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NJNBM"
                fase="1"
                serie="SB"
                label="Campeonato Nacional Sub-21 (JB) - Sul B"
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          {/* Menu Juniores B Masculino - 2ªFase*/}

          <CSSTransition
  in={activeMenu === "NJNBM_2Fase"}
  timeout={500}
  classNames="menu-secondary"
  unmountOnExit
  onEnter={calcHeight}
>
  <div>
    <DropdownItem 
      goToMenu="main" 
      label="&larr; Voltar"
      setActiveMenu={setActiveMenu}
    />
    <div className="menu">
    <DropdownItem 
  campeonato="NJNBM"
  fase="2"
  serie="P"
  label="Série Primeiros"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
/>

<DropdownItem 
  campeonato="NJNBM"
  fase="2"
  serie="US"
  label="Últimos Sul"
  onSubmit="true"
  setActiveMenu={setActiveMenu}
/>
    </div>
  </div>
</CSSTransition>

<CSSTransition
            in={activeMenu === "NJNBM_Final"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="masculino" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NJNBM"
                fase="F"
                serie="F"
                label="Campeonato Nacional Sub-21 (JB) Masculinos - Fase Final"
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "feminino"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJNBF_1Fase" 
              label="1º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJNBF_2Fase" 
              label="2º Fase"
              setActiveMenu={setActiveMenu}
              />
              <DropdownItem 
              goToMenu="NJNBF_Final" 
              label="Fase Final"
              setActiveMenu={setActiveMenu}
            />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "NJNBF_1Fase"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="main" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NJNBF"
                fase="1"
                serie="NA"
                label="Campeonato Nacional Sub-21 (JB) - Norte A"
                onSubmit="true"
              />
              <DropdownItem
                campeonato="NJNBF"
                fase="1"
                serie="NB"
                label="Campeonato Nacional Sub-21 (JB) - Norte B"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NJNBF"
                fase="1"
                serie="SA"
                label="Campeonato Nacional Sub-21 (JB) - Sul A"
                onSubmit="true"
              />
               <DropdownItem
                campeonato="NJNBF"
                fase="1"
                serie="SB"
                label="Campeonato Nacional Sub-21 (JB) - Sul B"
                onSubmit="true"
              />
            </div>
          </CSSTransition>

          {/* Menu Juniores Feminino - 2ªFase*/}

          <CSSTransition
  in={activeMenu === "NJNBF_2Fase"}
  timeout={500}
  classNames="menu-secondary"
  unmountOnExit
  onEnter={calcHeight}
>
  <div>
    <DropdownItem 
      goToMenu="main" 
      label="&larr; Voltar"
      setActiveMenu={setActiveMenu}
    />
    <div className="menu">
    <DropdownItem 
  campeonato="NJNBF"
  fase="2"
  serie="P"
  label="Série Primeiros"
  onSubmit="true"
  setActiveMenu={setActiveMenu}

/>

<DropdownItem
  label="Série Últimos"
  goToMenu="NJNBF_2Fase_SU"
  setActiveMenu={setActiveMenu}
/>
    </div>
  </div>
</CSSTransition>


          {/* Menu Juniores Feminino - 2ªFase - Série Últimos*/}

          <CSSTransition
            in={activeMenu === "NJNBF_2Fase_SU"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="NJNBF_2Fase" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
               <DropdownItem 
                campeonato="NJNBF"
                fase="2"
                serie="NA"
                label="Série A Norte"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
                formAction={true}
              />
              <DropdownItem 
                campeonato="NJNBF"
                fase="2"
                serie="SA"
                label="Série A Sul"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
                formAction={true}
              />
              <DropdownItem 
                campeonato="NJNBF"
                fase="2"
                serie="NB"
                label="Série B Norte"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
                formAction={true}
              />
              <DropdownItem 
                campeonato="NJNBF"
                fase="2"
                serie="SB"
                label="Série B Sul"
                onSubmit="true"
                setActiveMenu={setActiveMenu}
                formAction={true}
              />
            </div>
          </CSSTransition>

          <CSSTransition
            in={activeMenu === "NJNBF_Final"}
            timeout={500}
            classNames="menu-secondary"
            unmountOnExit
            onEnter={calcHeight}
          >
            <div className="menu">
            <DropdownItem 
              goToMenu="feminino" 
              label="&larr; Voltar"
              setActiveMenu={setActiveMenu}
              />
            <DropdownItem
                campeonato="NJNBF"
                fase="F"
                serie="F"
                label="Campeonato Nacional Sub-21 (JB) Femininos - Fase Final"
                onSubmit="true"
              />
            </div>
          </CSSTransition>
        </div>
      )}
    </div>
  );
};

const DropdownItem = ({ campeonato, fase, serie, label, goToMenu, setActiveMenu, onSubmit }) => {
const buttonRef = React.useRef(null);
  
    const handleClick = () => {
      if (goToMenu) {
        setActiveMenu(goToMenu);
      } else if (onSubmit) {
        const selector = `#${campeonato}-${fase}-${serie}`.replace(/[^a-zA-Z0-9-_]/g, '-');
        const form = document.querySelector(selector);
        if (form) {
          form.submit();
        }
      }
    };
  
    return (
      <div className="menu-item" onClick={handleClick}>
        {onSubmit ? (
          <form
            onSubmit={(e) => {
            }}
            action="https://www.portugalvoleibol.com/classificacao/classificacoes_react"
            method="POST"
          >
            <input type="hidden" name="campeonato" value={campeonato} />
            <input type="hidden" name="fase" value={fase} />
            <input type="hidden" name="serie" value={serie} />
            <button type="submit" ref={buttonRef}>
              {label}
            </button>
          </form>
        ) : (
          <button type="button" ref={buttonRef}>
            {label}
          </button>
        )}
      </div>
    );
  };
  
  export default MenuSub21JB;